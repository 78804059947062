export default class NaipeClass {
  number;
  type;
  width;
  visible;
  constructor(number = 0, type, width = 40, visible = true) {
    this.number = number;
    this.type = type;
    this.width = width
    this.visible = visible;
  }

  getValue() {
    switch ((this.number + '').toUpperCase()) {

      case 'J':
      case 'Q':
      case 'K':
        return 10;
      case 'A': return 11;
    }
    return Number.parseInt(this.number);
  }
}
