import { createI18n } from "vue-i18n";
import store from '@/store/index'
import en from '@/lang/en'
import es from '@/lang/es'
import pt from '@/lang/pt'

const i18n = createI18n({
    legacy: false,
    locale: store.state.language,
    fallbackLocale: store.state.language,
    messages: {
        es: {
            message: es
        },
        en: {
            message: en
        },
        pt: {
            message: pt
        },
    }
})

export default i18n