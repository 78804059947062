<template>
  <router-view />
</template>

<style lang="scss">
@import "@/theme/theme.scss";

html,
body {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

#app {
  font-family: BAHNSCHRIFT;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
}
</style>
