import { createStore } from "vuex";
import config from "./config"
import { sendBets, getMainBetAmount } from "./utils"
import Chip from "./model/chip";
import NaipeClass from "./model/naipe";
import { instance as axios } from "@/api/api";

import {
  INIT_BALANCE,
  INIT_CHIPS,
  OPEN_CHIP_LIST,
  CLOSE_CHIP_LIST,
  SET_ACTIVE_CHIP,
  ADD_BET,
  POP_BET,
  ADD_FAVORITE_BET,
  REMOVE_FAVORITE_BET,
  ADD_LAST_BET,
  SET_FULLSCREEN,
  ADD_NAIPE_HAND,
  ADD_NAIPE_HAND2,
  ADD_NAIPE_TABLE,
  SET_TEXT,
  SET_IMG_ACTION_HAND,
  INIT_BET_LIMITS,
  SET_BET_LIMITS,
  SET_STREAMING_ERROR,
  SET_STREAMING,
  SET_VIDEO_QUALITY,
  SET_VOLUMEN,
  SET_VIDEO_RELOAD_BTN,
  SET_INFO_TABLE,
  SET_INFO_ROUND,
  SET_USER_INFO,
  SET_CROUPIER_INFO,
  SET_ZOOM,
  SET_AUTO_STAND,
  CHECK_AUTO_STAND,
  SET_AUTO_STAND_NEXT_ROUND,
  SET_AUTO_STAND_NEXT_ROUND_VALUE,
  ADD_MESSAGE,
  SET_STREAM_CHANNEL,
  SET_LANGUAGE,
  DOUBLE_BET,
  SAVE_BETS_HISTORY,
  CHECK_INACTIVITY,
  SET_CHAT_BOX,
  SET_SETTINGS_BOX,
  SET_HISTORY_BOX,
  SET_LANGUAGE_BOX,
  SET_INFO_BOX,
  SET_VOLUME_BOX,
  SET_HELP_BOX,
  SET_SOCKET,
  SET_PHASE,
  SET_WL_DATA,
  SET_LATERAL_BETS,
  SET_PLAYER_CONFIG,
  SET_AUTO_STAND_VALUE,
  UPDATE_REMAINING_PLAYERS, SET_ERROR, SET_LOBBY_URL,
} from "./type/mutations";
import {
  BET_SUM,
  CHIP_ACTIVE,
  CENTER_BET,
  TABLE_SUM,
  HAND_SUM,
  HAND_SUM2,
  GET_STREAMING,
} from "./type/getters";
import {
  INIT,
  SET_CHIP,
  SET_GET_CARD,
  SET_STAND,
  SET_2X,
  SET_DIVIDE,
  SET_INSURANCE,
  PREPARE_NEXT_ROUND,
  OPEN_ACTION_PANEL,
  SET_BOX, CHANGE_PHASE, ADD_PLAYER_CARD, ADD_CROUPIER_CARD, SET_PLAYER, UPDATE_PLAYER, CHECK_WINNER
} from "./type/actions";

const {
  EV_GET_CARD,
  EV_STAND,
  EV_DOUBLE_BET,
  EV_DIVIDE,
  EV_SAFE_BET,
} = config.events
const {
  INITIAL_BET,
  RECEIVE_INITIAL_BET,
  SAFE_BET,
  INITIAL_OPTION,
  INITIAL_EMIT_CARD,
  OPTION,
  PROCESSING,
  FINISH,
  END
} = config.phases
const {
  WAIT_NEXT_GAME,
  WAIT_OTHER_PLAYERS,
  CROUPIER_WINS,
  NOT_MONEY
} = config.texts
const {
  BET_DEFAULT,
  BET_21_PLUS_3,
  BET_PERFECT_PAIRS
} = config
const {
  WIN_ICON,
  LOSE_ICON,
  GET_CARD_ICON,
  STAND_ICON,
  DOUBLE_BET_ICON,
  DIVIDE_ICON,
} = config.icons

export default createStore({
  state: {
    language: 'es',
    game: {
      player_token: '',
      game_id: '',
      currency: '',
      operator_id: '',
      casino_token: '',
      lobby_url: undefined,
      name: 'Sprint Gaming One Blackjack',
      chat_id: 'bjchat',
      chat_token: '',
    },
    socket: undefined,
    player: undefined,
    error: false,
    errorType: '',
    remainingPlayers: 0,
    game_phase: {
      phase: '',
      time: -1,
      text: '',
    },
    user: undefined,
    croupier: undefined,
    inactivity: 0,
    infoTable: '',
    infoRound: 0,
    messages: [],
    chat_channel: undefined,
    betLimitsOptions: undefined, // posibles rangos a establecer en la mesa
    betLimits: undefined, // rango de limites establecido | @admin
    currentBet: [], // apuesta actual
    lastBet: [], // ultima apuesta
    betsHistory: [], // historial de apuestas
    betHistoryTotal: 0,
    sentBetsStatus: undefined,
    favoriteBets: [],
    balance: 0,
    not_enough_balance: false,
    chips: [],
    chipListOpen: false,
    lastBetsRun: false,
    valueWin: 0,
    isWin: false,
    isWinMainBet: false,
    winMainValue: 0,
    cycleText: '',
    fullscreen: false,
    videoQuality: 'auto',
    videoStreaming: false, // true
    videoStreamingError: false,
    videoReloadBtn: false,
    volumen: 100,
    betErrorPosition: null,
    hand2: false,
    naipesTable: [],
    naipesHand: [],
    naipesHand2: [],
    imgActionHand: null,
    imgActionHand2: null,
    action: false,
    waiting: false,
    bet2X: false,
    getNaipe: false,
    stand: false,
    divideNaipes: false,
    divideAses: false,
    optionSelected: false,
    zoom: false,
    insuranceTime: false,
    insurance: undefined,
    noInsurance: false,
    autoStandNextRound: false,
    autoStandNextRoundValue: 0,
    autoStandEnabled: false,
    autoStandValue: 0,
    auto_stand_hand1: false,
    auto_stand_hand2: false,
    winner_hand: 0,
    soft_hand: {
      hand: false,
      hand2: false,
      table: false,
    },
    bet_21plus3: {
      bool: false,
      multiplier: 0,
      amount: 0,
    },
    perfect_pairs: {
      bool: false,
      multiplier: 0,
      amount: 0,
    },
    sure_bet: {
      bool: false,
      amount: 0,
    },
    player_blackjack: false,
    settingsBox: false,
    languageBox: false,
    chatBox: false,
    historyBox: false,
    infoBox: false,
    volumeBox: false,
    helpBox: false,
  },
  getters: {
    [GET_STREAMING](state) {
      return state.videoStreaming
    },
    // this return the sum of all bets current in place
    [BET_SUM]: (state) => {
      let out = 0;
      state.currentBet.forEach(element => {
        out += element.val;
      });
      return out;
    },
    // this return the chip is current active
    [CHIP_ACTIVE]: (state) => {
      return state.chips.find(c => c.active)
    },
    // this return true or false if is bet on the center button
    [CENTER_BET]: (state) => {
      return state.currentBet.find(b => b.type === BET_DEFAULT) !== undefined
    },
    // return the sum of naipes in table
    [TABLE_SUM]: (state) => {
      let sum = 0;
      state.naipesTable.forEach(card => {
        if (card.visible)
          switch (card.number) {
            case 'none':
            case 'A':
              break;
            case 'J':
            case 'Q':
            case 'K':
              sum += 10;
              break;
            default:
              sum += parseInt(card.number);
              break;
          }
      })
      const ases = state.naipesTable.filter(item => item.number === 'A' && item.visible)
      if (ases.length) {
        let as_value_11 = false
        for (let i = 0; i < ases.length; i++) {
          console.log(i)
          if (sum <= 10) {
            sum += 11
            as_value_11 = true
            state.soft_hand.table = true
          }
          else {
            sum += 1
            !as_value_11 && (state.soft_hand.table = false)
          }
        }
        // chequea si se paso por tener un As con valor 11 | le otorga valor 1
        if (sum > 21 && as_value_11) sum -= 10
      }
      sum === 21 && (state.soft_hand.table = false)
      return sum;
    },
    // return the sum of naipes in hand
    [HAND_SUM]: (state) => {
      let sum = 0;
      state.naipesHand.forEach(card => {
        if (card.visible)
          switch (card.number) {
            case 'none':
            case 'A':
              break;
            case 'J':
            case 'Q':
            case 'K':
              sum += 10;
              break;
            default:
              sum += parseInt(card.number);
              break;
          }
      })
      const ases = state.naipesHand.filter(item => item.number === 'A' && item.visible)
      if (ases.length) {
        let as_value_11 = false
        for (let i = 0; i < ases.length; i++) {
          console.log(i)
          if (sum <= 10) {
            sum += 11
            as_value_11 = true
            state.soft_hand.hand = true
          }
          else {
            sum += 1
            !as_value_11 && (state.soft_hand.hand = false)
          }
        }
        // chequea si se paso por tener un As con valor 11 | le otorga valor 1
        if (sum > 21 && as_value_11) sum -= 10
      }
      sum === 21 && (state.soft_hand.hand = false)
      return sum;
    },
    // return the sum of naipes in hand 2
    [HAND_SUM2]: (state) => {
      let sum = 0;
      state.naipesHand2.forEach(card => {
        if (card.visible)
          switch (card.number) {
            case 'none':
            case 'A':
              break;
            case 'J':
            case 'Q':
            case 'K':
              sum += 10;
              break;
            default:
              sum += parseInt(card.number);
              break;
          }
      })
      const ases = state.naipesHand2.filter(item => item.number === 'A' && item.visible)
      if (ases.length) {
        let as_value_11 = false
        for (let i = 0; i < ases.length; i++) {
          console.log(i)
          if (sum <= 10) {
            sum += 11
            as_value_11 = true
            state.soft_hand.hand2 = true
          }
          else {
            sum += 1
            !as_value_11 && (state.soft_hand.hand2 = false)
          }
        }
        // chequea si se paso por tener un As con valor 11 | le otorga valor 1
        if (sum > 21 && as_value_11) sum -= 10
      }
      sum === 21 && (state.soft_hand.hand2 = false)
      return sum;
    },
  },
  mutations: {
    [INIT_BALANCE](state, balance) {
      state.balance = Number(balance)
    },
    [INIT_CHIPS](state, arr) {
      for (const chip of arr) {
        if (chip.value >= state.betLimits?.min && chip.value <= state.betLimits?.max) {
          state.chips.push(new Chip(chip.value, chip.color))
        }
      }
      state.chips[0].active = true;
    },
    // open the chip list to select the value of the bet
    [OPEN_CHIP_LIST](state) {
      state.chipListOpen = !state.chipListOpen;
    },
    // activate a chip to bet with it
    [CLOSE_CHIP_LIST](state) {
      if (state.chipListOpen) {
        state.chipListOpen = false;
      }
    },
    // close the chip list
    [SET_ACTIVE_CHIP](state, position) {
      if (state.chips[position].val <= state.balance) {
        this.getters[CHIP_ACTIVE].active = false;
        state.chips[position].active = true;
      }
    },
    [ADD_BET](state, bet) {
      if (state.game_phase.phase !== INITIAL_BET) return
      if (bet.val > state.balance || bet.val > state.betLimits.max || bet.val < state.betLimits.min) return;
      if (state.currentBet.find(b => b.type === BET_DEFAULT) === undefined) {
        if (bet.type !== BET_DEFAULT) {
          state.betErrorPosition = bet.type;
          setTimeout(() => {
            state.betErrorPosition = null;
          }, 2000);
          return;
        }
      }
      if (bet.order === -1) {
        bet.order = state.currentBet.length
      }
      // apuesta | si ya existe incrementa el valor
      const target = state.currentBet.find(b => b.type === bet.type)
      if (target) {
        if (target.val + bet.val > state.betLimits.max) return;
        target.val += bet.val
      }
      else state.currentBet.push(bet)

      state.balance -= bet.val;
    },
    [POP_BET](state) {
      if (state.game_phase.phase !== INITIAL_BET || state.currentBet.length === 0) return;
      const bet = state.currentBet.pop()
      state.balance += bet.val
      while (state.currentBet.length > 0 && state.currentBet[state.currentBet.length - 1].order === bet.order) {
        state.balance += state.currentBet.pop().val
      }
    },
    [DOUBLE_BET](state) {
      state.currentBet.forEach(bet => {
        state.balance -= bet.val
        bet.val *= 2
      })
      // sendBets(state)
    },
    [ADD_FAVORITE_BET](state) {
      if (state.favoriteBets.length < 5)
        if (state.currentBet.length > 0) {
          if (state.favoriteBets.find(b => JSON.stringify(b.bet) === JSON.stringify(state.currentBet))) return
          state.favoriteBets.push(new Object({
            name: state.favoriteBets.length === 0 ? 1 : state.favoriteBets[state.favoriteBets.length - 1].name + 1,
            bet: [...state.currentBet]
          }));
        }
    },
    [REMOVE_FAVORITE_BET](state, bet) {
      const pos = state.favoriteBets.indexOf(bet);
      state.favoriteBets.splice(pos, 1);
    },
    // set fullscreen mode
    [SET_FULLSCREEN](state, val) {
      state.fullscreen = val;
    },
    // add a naipe to the hand
    [ADD_NAIPE_HAND](state, naipe) {
      state.naipesHand.push(naipe)
    },
    // add a naipe to the hand 2
    [ADD_NAIPE_HAND2](state, naipe) {
      state.naipesHand2.push(naipe)
    },
    [ADD_NAIPE_TABLE](state, naipe) {
      if (state.game_phase.phase !== INITIAL_EMIT_CARD) {
        let hidden = state.naipesTable.find(n => n.visible === false)
        if (hidden) {
          hidden.number = naipe.number
          hidden.type = naipe.type
          hidden.visible = true
        } else {
          state.naipesTable.push(naipe)
        }
      } else {
        state.naipesTable.push(naipe)
      }
    },
    [SET_TEXT](state, val) {
      state.cycleText = val;
    },
    [SET_IMG_ACTION_HAND](state, val) {
      if (this.state.winner_hand !== 0) {
        this.state.winner_hand === 1 && (state.imgActionHand = val)
        this.state.winner_hand === 2 && (state.imgActionHand2 = val)
        return
      }
      if (state.hand2 && state.naipesHand2.length > 1) {
        state.imgActionHand2 = val;
      }
      else {
        state.imgActionHand = val;
      }
    },
    /**
     * Inicia los limites para la mesa
     * @param state
     * @param {object} value Limits to set
     */
    [INIT_BET_LIMITS](state, value) { // [{ min: 10, max: 500 }, { min: 400, max: 2000 }]
      state.betLimits = value
    },
    // establecer limite | @user
    [SET_BET_LIMITS](state, limit) {
      state.betLimits = limit
    },
    [SET_STREAMING](state, bool) {
      state.videoStreaming = bool
    },
    [SET_STREAMING_ERROR](state, bool) {
      state.videoStreamingError = bool
    },
    [SET_VIDEO_QUALITY](state, value) {
      state.videoQuality = value
    },
    [SET_VOLUMEN](state, value) {
      state.volumen = value
    },
    [SET_VIDEO_RELOAD_BTN](state, bool) {
      state.videoReloadBtn = bool
    },
    [SET_INFO_TABLE](state, value) {
      state.infoTable = value
    },
    [SET_INFO_ROUND](state, value) {
      state.infoRound = value
    },
    [SET_USER_INFO](state, user) {
      state.user = user
    },
    [SET_CROUPIER_INFO](state, croupier) {
      state.croupier = croupier
    },
    [SET_ZOOM](state, zoom) {
      state.zoom = zoom;
    },
    [SET_AUTO_STAND](state, value) {
      state.autoStandEnabled = value
    },
    [SET_AUTO_STAND_VALUE](state, value) {
      state.autoStandValue = value
    },
    [SET_AUTO_STAND_NEXT_ROUND](state, bool) {
      state.autoStandNextRound = bool
    },
    [SET_AUTO_STAND_NEXT_ROUND_VALUE](state, val) {
      state.autoStandNextRoundValue = val
    },
    // checks if auto-stand should trigger
    [CHECK_AUTO_STAND](state, hand = 1) {
      let sum = hand === 1 ? this.getters[HAND_SUM] : this.getters[HAND_SUM2]
      function checkSoftHand() {
        switch (hand) {
          case 1:
            if (state.naipesHand.some(item => item.number === 'A')) {
              let c = 0
              state.naipesHand.forEach(card => {
                card.number !== 'A' && (c += card.getValue())
              })
              if (c < 10) {
                state.auto_stand_hand1 = false
                return
              }
            }
            state.auto_stand_hand1 = true
            break
          case 2:
            if (state.naipesHand2.some(item => item.number === 'A')) {
              let c = 0
              state.naipesHand2.forEach(card => {
                card.number !== 'A' && (c += card.getValue())
              })
              if (c < 10) {
                state.auto_stand_hand2 = false
                return
              }
            }
            state.auto_stand_hand2 = true
            break
        }
      }

      if (sum >= state.autoStandValue) {
        if (hand === 1) {
          if (state.naipesHand.length === 2) {
            if (state.naipesHand[0].getValue() === state.naipesHand[1].getValue()) { // check divide
              state.auto_stand_hand1 = false
              return;
            }
            else state.auto_stand_hand1 = true
          }
        }
        checkSoftHand()
      }
    },
    [ADD_MESSAGE](state, msg) {
      state.messages.push(msg)
    },
    [SET_STREAM_CHANNEL](state, channel) {
      state.chat_channel = channel
    },
    [SET_LANGUAGE](state, lang) {
      state.language = lang
    },
    [SAVE_BETS_HISTORY](state, data) {
      state.betsHistory = [...data.bets]
      state.betHistoryTotal = data.total
    },
    [CHECK_INACTIVITY](state) {
      state.inactivity = state.currentBet.length ? 0 : state.inactivity + 1
      /* !state.currentBet.length ? state.inactivity++ : (state.inactivity = 0) */
    },
    [SET_LANGUAGE_BOX](state, val) {
      state.languageBox = val;
    },
    [SET_CHAT_BOX](state, val) {
      state.chatBox = val;
    },
    [SET_SETTINGS_BOX](state, val) {
      state.settingsBox = val;
    },
    [SET_HISTORY_BOX](state, val) {
      state.historyBox = val;
    },
    [SET_VOLUME_BOX](state, val) {
      state.volumeBox = val;
    },
    [SET_INFO_BOX](state, val) {
      state.infoBox = val;
    },
    [SET_HELP_BOX](state, val) {
      state.helpBox = val;
    },
    [SET_SOCKET](state, val) {
      state.socket = val
    },
    [SET_PHASE](state, payload) {
      state.game_phase.phase = payload.phase
      state.game_phase.time = payload.timeWait - 1
      if (payload.countPlayers) state.remainingPlayers = payload.countPlayers
    },
    [SET_WL_DATA](state, payload) {
      const { gameId, token, currency, operatorId, casinoToken, language } = payload
      state.game.game_id = gameId
      state.game.player_token = token
      state.game.currency = currency
      state.game.operator_id = operatorId
      state.game.casino_token = casinoToken
      state.language = language
    },
    [SET_LATERAL_BETS](state, payload){
      if (payload.perfectCouple !== 'lose') {
        const bet_pp = state.currentBet.find(item => item.type === BET_PERFECT_PAIRS)
        state.perfect_pairs.bool = true
        if (bet_pp) {
          state.perfect_pairs.multiplier = payload.perfectCoupleIndex
          state.perfect_pairs.amount = state.perfect_pairs.multiplier * bet_pp.val
        } else {
          state.perfect_pairs.multiplier = payload.perfectCoupleIndex
          state.perfect_pairs.amount = `${state.perfect_pairs.multiplier}:1`
        }
      }
      if (payload.twentyThreePlusOne !== 'lose') {
        const bet_21 = state.currentBet.find(item => item.type === BET_21_PLUS_3)
        state.bet_21plus3.bool = true
        if (bet_21) {
          state.bet_21plus3.multiplier = payload.twentyThreePlusOneIndex
          state.bet_21plus3.amount = state.bet_21plus3.multiplier * bet_21.val
        } else {
          state.bet_21plus3.multiplier = payload.twentyThreePlusOneIndex
          state.bet_21plus3.amount = `${state.bet_21plus3.multiplier}:1`
        }
      }
    },
    [SET_PLAYER_CONFIG](state, data){
      const { language, selfPlate } = data
      state.language = language.toLowerCase()
      state.autoStandNextRoundValue = selfPlate
      state.autoStandValue = selfPlate
      state.autoStandNextRound = selfPlate !== 0
      state.autoStandEnabled = selfPlate !== 0
    },
    [UPDATE_REMAINING_PLAYERS](state){
      state.remainingPlayers > 0 && state.remainingPlayers--
    },
    [SET_ERROR](state, data){
      state.error = data.error
      state.errorType = data.type || ''
    },
    [SET_LOBBY_URL](state, value){
      state.game.lobby_url = value
    },
  },
  actions: {
    /**
     * INITS GAME MAIN DATA
     * @param commit
     * @param {object} data game data
     */
    [INIT]({ commit }, data) {
      const {
        player, croupier, table, round, limits, balance, chips,
      } = data
      commit(INIT_BET_LIMITS, limits)
      commit(INIT_CHIPS, chips)
      commit(INIT_BALANCE, balance)
      commit(SET_INFO_TABLE, table)
      commit(SET_INFO_ROUND, round)
      commit(SET_USER_INFO, player)
      commit(SET_CROUPIER_INFO, croupier)
    },
    /**
     * HANDLE WHEN PHASES CHANGE
     * @param commit
     * @param dispatch
     * @param state
     * @param payload phase data
     */
    [CHANGE_PHASE]({commit, dispatch, state}, payload) {
      commit(SET_PHASE, payload)
      const isHand2Current = state.hand2 && state.naipesHand2.length > 1
      const sum = isHand2Current ? this.getters[HAND_SUM2] : this.getters[HAND_SUM]
      const playerLose = isHand2Current ? this.getters[HAND_SUM2] > 21 : this.getters[HAND_SUM] > 21
      const playerHas21 = isHand2Current ? this.getters[HAND_SUM2] === 21 : this.getters[HAND_SUM] === 21
      const playerBlackjack = playerHas21 && !state.hand2 && state.naipesHand.length === 2
      const standWithAses = state.naipesHand.length > 1 || state.naipesHand2 > 1
      const isOptions = state.game_phase.phase === OPTION || state.game_phase.phase === INITIAL_OPTION
      if (isOptions) {
        if (playerHas21 || (state.divideAses && standWithAses)) commit(SET_IMG_ACTION_HAND, STAND_ICON)
      }
      if (playerLose && !state.isWinMainBet) commit(SET_IMG_ACTION_HAND, LOSE_ICON)
      if (playerBlackjack) commit(SET_IMG_ACTION_HAND, WIN_ICON)
      if (isHand2Current) state.stand = false
      function getText() {
        const isInitial = state.game_phase.phase === INITIAL_BET
        const isFinish = state.game_phase.phase === FINISH
        const isOption = state.game_phase.phase === OPTION
        const isOptionsAny = state.game_phase.phase === OPTION || state.game_phase.phase === INITIAL_OPTION
        const text = (state.currentBet.length || (isInitial && state.player.enable)) ? state.game_phase.phase : WAIT_NEXT_GAME
        if (state.divideAses && state.naipesHand.length === 2 && state.naipesHand2.length === 2 && isOption) return WAIT_OTHER_PLAYERS
        if ((playerLose || playerHas21 || state.divideAses) && isOptionsAny) return WAIT_OTHER_PLAYERS
        if (state.stand && !isFinish) return WAIT_OTHER_PLAYERS
        if (state.bet2X) return state.naipesHand.length === 3 && !isFinish ? WAIT_OTHER_PLAYERS : state.game_phase.phase
        if (state.game_phase.phase === END && !state.isWin && state.currentBet.length) return CROUPIER_WINS
        return text
      }
      function canSelectOptions() {
        return !state.stand && !state.bet2X && sum < 21 && !state.divideAses
      }
      function checkAutoStand() {
        if (state.currentBet.length) {
          commit(CHECK_AUTO_STAND)
          if (state.auto_stand_hand1) {
            state.stand = true
            commit(SET_IMG_ACTION_HAND, STAND_ICON)
            commit(SET_TEXT, WAIT_OTHER_PLAYERS)
          }
        }
      }
      function checkBalance() {
        state.not_enough_balance = state.balance < state.betLimits.min
        state.not_enough_balance && commit(SET_TEXT, NOT_MONEY)
      }
      // commit(SET_PHASE, payload)
      commit(SET_TEXT, getText())
      switch (this.state.game_phase.phase) {
        case INITIAL_BET:
          checkBalance()
          break
        case RECEIVE_INITIAL_BET:
          sendBets(state)
          break
        case SAFE_BET:
          this.state.insuranceTime = true
          dispatch(OPEN_ACTION_PANEL)
          break
        case INITIAL_OPTION:
        case OPTION:
          this.state.autoStandEnabled && checkAutoStand()
          canSelectOptions() && dispatch(OPEN_ACTION_PANEL)
          break
        case PROCESSING:
          this.state.optionSelected = false
          break
        case END:
          commit(CHECK_INACTIVITY)
          dispatch(UPDATE_PLAYER)
          setTimeout(() => {
            dispatch(PREPARE_NEXT_ROUND)
          }, 4000)
          break
      }
    },
    /**
     * SET INITIAL DATA FOR PLAYER | PHASE | PLAYER CARDS | CROUPIER CARDS
     * @param commit
     * @param dispatch
     * @param data data
     */
    [SET_PLAYER]({commit, dispatch}, data) {
      this.state.player = data.player
      commit(SET_PLAYER_CONFIG, data.config)
      commit(SET_PHASE, { phase: data.phase, timeWait: 0 })
      // general croupier cards
      data.croupierHands.forEach(hand => dispatch(ADD_CROUPIER_CARD, hand))
      // general player cards
      data.playerHands.forEach(hand => dispatch(ADD_PLAYER_CARD, hand))
    },
    /**
     * ADD CARD TO PLAYER HANDS
     * @param dispatch
     * @param commit
     * @param state
     * @param payload new card
     */
    [ADD_PLAYER_CARD]({ dispatch, commit, state }, payload) {
      if (!state.player.enable && state.naipesHand.length === 2) return;
      const card = new NaipeClass(payload.name, payload.suit)
      if (state.divideAses) {
        state.naipesHand.length === 2 ? commit(ADD_NAIPE_HAND2, card) : commit(ADD_NAIPE_HAND, card)
        return;
      }
      if ((state.divideNaipes && state.stand) || state.naipesHand2.length > 1 || (this.getters[HAND_SUM] >= 21 && state.hand2))
        commit(ADD_NAIPE_HAND2, card)
      else
        commit(ADD_NAIPE_HAND, card)
    },
    /**
     * ADD CARD TO CROUPIER HAND
     * @param commit
     * @param payload new card
     */
    [ADD_CROUPIER_CARD]({commit}, payload) {
      const visible = !!payload
      commit(ADD_NAIPE_TABLE, new NaipeClass(payload?.name, payload?.suit, 40, visible));
    },
    /**
     * OPEN OPTIONS PANEL TO SELECT
     */
    [OPEN_ACTION_PANEL]() {
      this.state.action = true;
    },
    /**
     * SET INSURANCE | SAFE BET
     * @param commit
     * @param value Boolean
     */
    [SET_INSURANCE]({ commit }, value) {
      this.state.insurance = value;
      this.state.optionSelected = true
      if (value) {
        this.state.socket.emit(EV_SAFE_BET, undefined, (res) => {
          this.state.balance -= this.state.currentBet[0].val / 2;
        })
      }
      this.state.insuranceTime = false
      this.state.action = false;
    },
    /**
     * SET CHIP ON POSITION
     * @param commit
     * @param position chip position
     */
    [SET_CHIP]({ commit }, position) {
      commit(SET_ACTIVE_CHIP, position);
    },
    /**
     * ADD LAST BET
     * @param commit
     */
    [ADD_LAST_BET]({ commit }) {
      if (this.state.game_phase.phase !== INITIAL_BET) return
      const main = this.state.lastBet.find(bet => bet.type === BET_DEFAULT)
      const bet21 = this.state.lastBet.find(bet => bet.type === BET_21_PLUS_3)
      const betPP = this.state.lastBet.find(bet => bet.type === BET_PERFECT_PAIRS)
      main && commit(ADD_BET, main)
      bet21 && commit(ADD_BET, bet21)
      betPP && commit(ADD_BET, betPP)
    },
    /**
     * GET CARD
     * @param commit
     */
    [SET_GET_CARD]({ commit }) {
      this.state.optionSelected = true
      this.state.socket.emit(EV_GET_CARD, undefined, (res) => {
        this.state.action = false
        commit(SET_IMG_ACTION_HAND, GET_CARD_ICON)
      })
    },
    /**
     * STAND
     * @param commit
     */
    [SET_STAND]({ commit }) {
      this.state.stand = true
      this.state.optionSelected = true
      this.state.socket.emit(EV_STAND, undefined, (res) => {
        this.state.action = false
        commit(SET_IMG_ACTION_HAND, STAND_ICON)
      })
    },
    /**
     * DOUBLE BET
     * @param commit
     */
    [SET_2X]({ commit }) {
      this.state.bet2X = true
      this.state.optionSelected = true
      this.state.socket.emit(EV_DOUBLE_BET, undefined, (res) => {
        this.state.action = false;
        this.state.balance -= this.state.currentBet[0].val
        this.state.currentBet[0].val *= 2
        commit(SET_IMG_ACTION_HAND, DOUBLE_BET_ICON);
      })
    },
    /**
     * DIVIDE HAND
     * @param commit
     */
    [SET_DIVIDE]({ commit }) {
      this.state.divideAses = this.state.naipesHand[0].number === 'A' && this.state.naipesHand[1].number === 'A'
      this.state.divideNaipes = true;
      this.state.hand2 = true
      this.state.optionSelected = true
      this.state.socket.emit(EV_DIVIDE, undefined, (res) => {
        const card2 = this.state.naipesHand.pop()
        setTimeout(() => this.state.naipesHand2.push(card2), 500)
        const mainBet = this.state.currentBet.find(item => item.type === BET_DEFAULT)
        this.state.balance -= mainBet.val
        mainBet.val *= 2
        this.state.action = false;
        commit(SET_IMG_ACTION_HAND, DIVIDE_ICON);
      })
    },
    /**
     * CHECK WINNER
     * @param commit
     * @param state
     * @param data  winner data
     */
    [CHECK_WINNER]({ commit, state }, data){
      if (!data.betTypes) return;
      let winMain, winLaterals, winSureBet = false
      const { mainBet, twentyThreePlusOne, perfectCouples, sureBet } = data.betTypes
      const h1 = this.getters[HAND_SUM] > 21 ? 0 : this.getters[HAND_SUM]
      const h2 = this.getters[HAND_SUM2] > 21 ? 0 : this.getters[HAND_SUM2]
      state.winner_hand = h1 >= h2 ? 1 : 2
      if (mainBet.result > 0) {
        winMain = true
        state.isWinMainBet = true
        state.winMainValue = getMainBetAmount(state, data)
        state.valueWin += state.winMainValue
        commit(SET_IMG_ACTION_HAND, WIN_ICON)
      } else commit(SET_IMG_ACTION_HAND, LOSE_ICON)
      if (twentyThreePlusOne.result > 0) {
        winLaterals = true
        state.bet_21plus3.bool = true
        state.valueWin += state.bet_21plus3.amount
      }
      if (perfectCouples.result > 0) {
        winLaterals = true
        state.perfect_pairs.bool = true
        state.valueWin += state.perfect_pairs.amount
      }
      if (sureBet.result > 0) {
        winSureBet = true
        state.sure_bet.bool = true
        state.sure_bet.amount = sureBet.result - sureBet.value
        state.valueWin += state.sure_bet.amount
      }
      state.isWin = winMain || winLaterals || winSureBet
    },
    /**
     * SET BOX
     * @param commit
     * @param box
     */
    [SET_BOX]({ commit }, box) {
      const boxs = [
        SET_SETTINGS_BOX,
        SET_CHAT_BOX,
        SET_LANGUAGE_BOX,
        SET_HISTORY_BOX,
        SET_VOLUME_BOX,
        SET_INFO_BOX,
        SET_HELP_BOX
      ]
      for (let i = 0; i < boxs.length; i++) {
        if (box.mutation == boxs[i]) {
          commit(boxs[i], box.val)
        } else {
          commit(boxs[i], false)
        }
      }
    },
    /**
     * UPDATE PLAYER DATA
     * @param commit
     * @returns {Promise<void>}
     */
    async [UPDATE_PLAYER]({ commit }) {
      const socket = this.state.player.socketId
      try {
        const { data } = await axios.get(`/game/player/${socket}`)
        const { player, config, identifyNumber: round, balance } = data
        this.state.player = player
        commit(SET_PLAYER_CONFIG, config)
        commit(SET_INFO_ROUND, round)
        commit(INIT_BALANCE, balance)
        commit(SET_ERROR, false)
      } catch (e) {
        commit(SET_ERROR, true)
      }
    },
    /**
     * RESET STORE FOR NEXT ROUND
     */
    [PREPARE_NEXT_ROUND]() {
      // action for select options
      this.state.action = false
      // sending bets status
      this.state.sentBetsStatus = undefined
      // not money
      this.state.not_enough_balance = false
      // last bet
      this.state.lastBet = [...this.state.currentBet]
      // current bet
      this.state.currentBet = []
      // winning
      this.state.isWin = false
      this.state.valueWin = 0
      this.state.winMainValue = 0
      this.state.isWinMainBet = false
      this.state.winner_hand = 0
      // insurance | safe bet
      this.state.insuranceTime = false
      this.state.insurance = undefined
      // hands
      this.state.naipesTable = []
      this.state.naipesHand = []
      this.state.naipesHand2 = []
      this.state.hand2 = false
      // action icons
      this.state.imgActionHand = null
      this.state.imgActionHand2 = null
      // options
      this.state.bet2X = false
      this.state.stand = false
      this.state.divideNaipes = false
      this.state.divideAses = false
      // auto stand
      this.state.auto_stand_hand1 = false
      this.state.auto_stand_hand2 = false
      // soft hands
      this.state.soft_hand.hand = false
      this.state.soft_hand.hand2 = false
      this.state.soft_hand.table = false
      // 21+3
      this.state.bet_21plus3.bool = false
      this.state.bet_21plus3.amount = 0
      this.state.bet_21plus3.multiplier = 0
      // perfect pairs
      this.state.perfect_pairs.bool = false
      this.state.perfect_pairs.amount = 0
      this.state.perfect_pairs.multiplier = 0
      // sure bet
      this.state.sure_bet.bool = false
      this.state.sure_bet.amount = 0
    },

  },
  modules: {},
});
