export const INIT_BALANCE = "initBalance";
export const INIT_CHIPS = "initChips";
export const OPEN_CHIP_LIST = "openChipList";
export const CLOSE_CHIP_LIST = "closeChipList";
export const SET_ACTIVE_CHIP = "setAciveChip";
export const ADD_BET = "addBet";
export const ADD_LAST_BET = "addLastBet";
export const POP_BET = "pullBet";
export const ADD_FAVORITE_BET = "addFavoriteBet";
export const REMOVE_FAVORITE_BET = "removeFavoriteBet";
export const SET_FULLSCREEN = "setFullscreen";
export const ADD_NAIPE_HAND = "addNaipeHand";
export const ADD_NAIPE_HAND2 = "addNaipeHand2";
export const ADD_NAIPE_TABLE = "addNaipeTable";
export const SET_IMG_ACTION_HAND = "setHandActionImg";
export const SET_TEXT = "setText";
export const INIT_BET_LIMITS = "initBetLimits"
export const SET_BET_LIMITS = "setBetLimits"
export const SET_STREAMING = "setStreaming"
export const SET_STREAMING_ERROR = "setStreamingError"
export const SET_VIDEO_QUALITY = "setVideoQuality"
export const SET_VOLUMEN = "setVolumen"
export const SET_VIDEO_RELOAD_BTN = "setVideoReloadBtn"
export const SET_INFO_TABLE = "setInfoTable"
export const SET_INFO_ROUND = "setInfoRound"
export const SET_USER_INFO = "setUserInfo"
export const SET_CROUPIER_INFO = "setCroupierInfo"
export const SET_ZOOM = "setZoom"
export const SET_AUTO_STAND = "setAutoStand"
export const SET_AUTO_STAND_VALUE = "setAutoStandValue"
export const SET_AUTO_STAND_NEXT_ROUND = "setAutoStandNextRound"
export const SET_AUTO_STAND_NEXT_ROUND_VALUE = "setAutoStandNextRoundValue"
export const CHECK_AUTO_STAND = "checkAutoStand"
export const ADD_MESSAGE = "addMessage"
export const SET_STREAM_CHANNEL = "setStreamChannel"
export const SET_LANGUAGE = "setLanguage"
export const DOUBLE_BET = "doubleBet"
export const SAVE_BETS_HISTORY = "saveHistory"
export const CHECK_INACTIVITY = "checkInactivity"
export const SET_CHAT_BOX = "setChatBox";
export const SET_SETTINGS_BOX = "setSettingsBox";
export const SET_HISTORY_BOX = "setHistoryBox";
export const SET_INFO_BOX = "setInfoBox";
export const SET_LANGUAGE_BOX = "setLanguageBox";
export const SET_VOLUME_BOX = "setVolumeBox";
export const SET_HELP_BOX = "setHelpBox";
export const SET_SOCKET = "setSocket"
export const SET_PHASE = "setPhase"
export const SET_WL_DATA = "setWlData"
export const SET_LATERAL_BETS = "setLateralBets"
export const SET_PLAYER_CONFIG = "setPlayerConfig"
export const UPDATE_REMAINING_PLAYERS = "setRemainingPlayers"
export const SET_ERROR = "setError"
export const SET_LOBBY_URL = "setLobbyUrl"