import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueFullscreen from 'vue-fullscreen'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import i18n from "@/i18n";

createApp(App)
  .use(store)
  .use(router)
  .use(VueFullscreen)
  .use(ElementPlus)
  .use(i18n)
  .mount("#app");
