const en = {
    // TOP MENU
    'select_limits'  : 'Select bet limits',
    'settings'       : 'Settings',
    'info'           : 'Information',
    'limits'         : 'Limits',
    'volume'         : 'Volume',
    'language'       : 'Language',
    'fullscreen'     : 'Fullscreen',
    'help'           : 'help',
    'history'        : {
        'debit'      : 'debit',
        'credit'     : 'credit',
        'main'       : 'history',
        'title'      : 'bet history',
        'records'    : 'records',
        'date'       : 'date',
        'trans'      : 'transaction',
        'amount'     : 'amount',
        'state'      : {
            'main'   : 'result',
            'win'    : 'won',
            'lose'   : 'lost',
            'draw'   : 'draw',
        },
        'end'        : 'These are all the results!',
        'no-records' : 'No bets have been placed!',
        'no-results' : 'No results were found!',
        'axios-err'  : 'An error occurred! Check your internet connection.',
        'start_date' : 'Start Date',
        'end_date'   : 'Final Date',
        'dist'       : 'Card Distribution',
        'points'     : 'points',
        'bet_details': 'bet details',
        'bet_type'   : 'Bet Type',
        'bet_amount' : 'Staked Amount',
        'won_amount' : 'Earned Amount',
        'tot_amount' : 'Total Amount',
        'mainBet'    : 'Main',
        'twentyThreePlusOne' : '21+3',
        'perfectCouples'     : 'Perfect Pairs',
        'sureBet'            : 'Insurance',
    },
    // LOBBY CONFIRM
    'exit_lobby'     : 'Are you sure you want to go back to lobby?',
    'cancel'         : 'Cancel',
    'confirm'        : 'Confirm',
    // INACTIVITY
    'inactivity'     : {
        'main'       : 'Inactivity',
        'title'      : 'Inactividad detected!',
        'msg'        : 'We sorry! You cannot stay idle in the game for longer.',
        'ok'         : 'Ok',
    },
    // SETTINGS BOX
    'general'        : 'General',
    'video'          : {
        'title'      : 'Video streaming',
        'subtitle'   : 'Activate to see live video of the table',
    },
    'reload'         : {
        'title'      : 'Reload video',
        'subtitle'   : 'Reload the video',
        'btn'        : 'Reload',
    },
    'video_quality'  : {
        'title'      : 'Video quality',
        'subtitle'   : 'Select the quality in which the video is transmitted',
        'btn'        : {
            'low'    : 'Low',
            'medium' : 'Medium',
            'high'   : 'High',
            'auto'   : 'Auto',
        },
    },
    'volume_in_box' : {
        'title'     : 'Game volume',
        'subtitle'  : 'Adjust game sound effects',
    },
    'auto_stand'     : {
        'main'       : 'Auto-Stand',
        'descript1'  : 'If Auto-Stand is on, it will stand for you automatically when your cards score is greater than or equal to the selected value.',
        'alert'      : 'Changes will be applied in the next round',
        'descript2'  : {
            'title'      : 'Auto-Stand will not stand for you when:',
            'subtitle1'  : '1- Split option is available',
            'subtitle2'  : '2- You have a Soft Hand (an Ace + the rest of the cards add up to less than 10 points)',
        },
        'disabled'   : 'Disabled',
    },
    // INFO BOX
    'table'        : 'Table',
    'round'        : 'Round',
    'croupier'     : 'Croupier',
    'player'       : 'Player',
    'game_version' : 'Game version',
    // LIMITS BOX
    'limits_box'     : {
        'title'      : 'Bet Limits',
        'main'       : 'Main',
        'secondary'  : 'Secondaries',
        'unset'      : 'Not set',
    },
    // CHAT
    'chat_form_msg'  : 'Write a message here',
    // MAIN SCREEN
    'months' : {
        '1'  : 'January',
        '2'  : 'February',
        '3'  : 'March',
        '4'  : 'April',
        '5'  : 'May',
        '6'  : 'June',
        '7'  : 'July',
        '8'  : 'August',
        '9'  : 'September',
        '10' : 'October',
        '11' : 'November',
        '12' : 'December',
    },
    'money'              : 'Balance',
    'total_bet'          : 'Total Bet',
    'auto_stand_on'      : 'auto-stand activated',
    'undo'               : 'Undo',
    'repeat_bet'         : 'Repeat last bet',
    'double_bet'         : 'Double bet',
    'chip_to_bet'        : 'Chip to bet',
    'all_side_bets'      : 'all side bets',
    'winning_value'      : 'You win',
    'main_bet_first'     : 'You must place a main bet',
    'zoom_in'            : 'Zoom in',
    'zoom_out'           : 'Zoom out',
    // BET PLACE
    'wait_next_game'     : 'Wait for next game',
    'wait_other_players' : 'Wait for other players',
    'initial_bet'        : 'Place your bets',
    'last_bets'          : 'last bets',
    'revice_initial_bet' : 'Bets closed',
    'bets_accepted'      : 'Bets accepted',
    'bets_rejected'      : 'Bets rejected',
    'processing'         : 'Dealing',
    'initial_emit_card'  : 'Dealing',
    'emit_card'          : 'Dealing',
    'safe_bet'           : 'Bet insurance?',
    'initial_option'     : 'Make a decision',
    'option'             : 'Make a decision',
    'finish'             : 'Dealing',
    'croupier_wins'      : 'Dealer wins',
    'not_money'          : 'Insufficient balance',
    'not_money_desc'     : 'your balance is not enough to bet',
    'game_pause'         : 'game paused',
    'game_cancel'        : 'game canceled',
    'yes'                : 'yes',
    'no'                 : 'no',
    'hand1'              : 'Your hand',
    'hand2'              : 'Your hand 2',
    // ACTION PANEL
    'double'             : 'double',
    'hit'                : 'hit',
    'stand'              : 'stand',
    'divide'             : 'split',
    // MODAL
    'error' : {
        'main'            : 'Oops!',
        'title'           : 'An error occurred!',
        'connection'      : 'Could not connect to the server. Please go back to the lobby and try again.',
        'action'          : 'The action could not be completed. Communication with the server failed.',
        'not_found'       : 'Page not found. Please go back to the lobby and try again.',
        'no_redirect'     : 'The request could not be sent. Please, try again.',
    },
}

export default en