const es = {
    // TOP MENU
    'select_limits'  : 'Seleccione los límites de apuesta',
    'settings'       : 'Ajustes',
    'info'           : 'Información',
    'limits'         : 'Límites',
    'volume'         : 'Volumen',
    'language'       : 'Lenguaje',
    'fullscreen'     : 'Pantalla Completa',
    'help'           : 'ayuda',
    'history'        : {
        'debit'      : 'débito',
        'credit'     : 'crédito',
        'main'       : 'historial',
        'title'      : 'historial de apuestas',
        'records'    : 'registros',
        'date'       : 'fecha',
        'trans'      : 'transacción',
        'amount'     : 'monto',
        'state'      : {
            'main'   : 'resultado',
            'win'    : 'ganada',
            'lose'   : 'perdida',
            'draw'   : 'empate',
        },
        'end'        : 'Estos son todos los resultados!',
        'no-records' : 'No se han realizado apuestas!',
        'no-results' : 'No se encontraron resultados!',
        'axios-err'  : 'Ocurrió un error! Revise su conexión a internet.',
        'start_date' : 'Fecha Inicial',
        'end_date'   : 'Fecha Final',
        'dist'       : 'Distribución de Cartas',
        'points'     : 'puntos',
        'bet_details': 'detalles de apuesta',
        'bet_type'   : 'Tipo Apuesta',
        'bet_amount' : 'Monto Apostado',
        'won_amount' : 'Monto Ganado',
        'tot_amount' : 'Monto Total',
        'mainBet'   : 'Principal',
        'twentyThreePlusOne' : '21+3',
        'perfectCouples'     : 'Parejas Perfectas',
        'sureBet'            : 'Seguro',
    },
    // LOBBY CONFIRM
    'exit_lobby'     : 'Seguro que desea regresar al lobby?',
    'cancel'         : 'Cancelar',
    'confirm'        : 'Confirmar',
    // INACTIVITY
    'inactivity'     : {
        'main'       : 'Inactividad',
        'title'      : 'Inactividad detectada!',
        'msg'        : 'Lo sentimos! No puede permanecer más tiempo inactivo en el juego.',
        'ok'         : 'Confirmar',
    },
    // SETTINGS BOX
    'general'        : 'General',
    'video'          : {
        'title'      : 'Transmisión de video',
        'subtitle'   : 'Active para ver video en vivo de la mesa',
    },
    'reload'         : {
        'title'      : 'Recargar video',
        'subtitle'   : 'Vuelva a cargar el video',
        'btn'        : 'Recargar',
    },
    'video_quality'  : {
        'title'      : 'Calidad de video',
        'subtitle'   : 'Selecciona la calidad en que se transmite el video',
        'btn'        : {
            'low'    : 'Baja',
            'medium' : 'Media',
            'high'   : 'Alta',
            'auto'   : 'Auto',
        },
    },
    'volume_in_box' : {
        'title'     : 'Volumen del juego',
        'subtitle'  : 'Ajuste los efectos de sonido del juego',
    },
    'auto_stand'        : {
        'main'          : 'Auto-Plante',
        'descript1'     : 'Si Auto-Plante está activado, se plantará por usted automáticamente cuando la puntuación de sus cartas sea mayor o igual al valor seleccionado.',
        'alert'         : 'Los cambios se aplicarán en la siguiente ronda',
        'descript2'     : {
            'title'     : 'Auto-Plante no se plantará por usted cuando:',
            'subtitle1' : '1- La opción de Dividir está disponible',
            'subtitle2' : '2- Tiene una Mano Blanda (un As + el resto de las cartas suman menos de 10 puntos)',
        },
        'disabled'      : 'Desactivado',
    },
    // INFO BOX
    'table'         : 'Mesa',
    'round'         : 'Ronda',
    'croupier'      : 'Crupier',
    'player'        : 'Jugador',
    'game_version'  : 'Versión del juego',
    // LIMITS BOX
    'limits_box'    : {
        'title'     : 'Límites de Apuesta',
        'main'      : 'Principal',
        'secondary' : 'Secundarias',
        'unset'     : 'Sin establecer',
    },
    // CHAT
    'chat_form_msg' : 'Escriba un mensaje aquí',
    // MAIN SCREEN
    'months' : {
        '1'  : 'Enero',
        '2'  : 'Febrero',
        '3'  : 'Marzo',
        '4'  : 'Abril',
        '5'  : 'Mayo',
        '6'  : 'Junio',
        '7'  : 'Julio',
        '8'  : 'Agosto',
        '9'  : 'Septiembre',
        '10' : 'Octubre',
        '11' : 'Noviembre',
        '12' : 'Diciembre',
    },
    'money'              : 'Saldo',
    'total_bet'          : 'Apuesta Total',
    'auto_stand_on'      : 'auto-plante activado',
    'undo'               : 'Deshacer',
    'repeat_bet'         : 'Repetir última apuesta',
    'double_bet'         : 'Doblar apuesta',
    'chip_to_bet'        : 'Ficha a apostar',
    'all_side_bets'      : 'todas ap. secundarias',
    'winning_value'      : 'Usted gana',
    'main_bet_first'     : 'Debe realizar una apuesta principal',
    'zoom_in'            : 'Acercar',
    'zoom_out'           : 'Alejar',
    // BET PLACE
    'wait_next_game'     : 'Espere el siguiente juego',
    'wait_other_players' : 'Espere a los otros jugadores',
    'initial_bet'        : 'Haga sus apuestas',
    'last_bets'          : 'últimas apuestas',
    'revice_initial_bet' : 'Apuestas cerradas',
    'bets_accepted'      : 'Apuestas aceptadas',
    'bets_rejected'      : 'Apuestas rechazadas',
    'processing'         : 'Repartiendo',
    'initial_emit_card'  : 'Repartiendo',
    'emit_card'          : 'Repartiendo',
    'safe_bet'           : 'Seguro de apuesta?',
    'initial_option'     : 'Tome una decisión',
    'option'             : 'Tome una decisión',
    'finish'             : 'Repartiendo',
    'croupier_wins'      : 'El crupier gana',
    'not_money'          : 'Saldo insuficiente',
    'not_money_desc'     : 'su saldo no es suficiente para apostar',
    'game_pause'         : 'juego en pausa',
    'game_cancel'        : 'juego cancelado',
    'yes'                : 'si',
    'no'                 : 'no',
    'hand1'              : 'Tu mano',
    'hand2'              : 'Tu mano 2',
    // ACTION PANEL
    'double'             : 'doblar',
    'hit'                : 'robar',
    'stand'              : 'quedarse',
    'divide'             : 'dividir',
    // MODAL
    'error' : {
        'main'            : 'Ups!',
        'title'           : 'Ocurrió un error!',
        'connection'      : 'No se pudo conectar con el servidor. Por favor regrese al lobby y vuelva a intentarlo.',
        'action'          : 'No se pudo completar la acción. Falló la comunicación con el servidor.',
        'not_found'       : 'No se encontró la página. Por favor regrese al lobby y vuelva a intentarlo.',
        'no_redirect'     : 'No se pudo enviar la solicitud. Por favor vuelva a intentarlo.',
    },
}

export default es