import config from "@/store/config";
const { BET_DEFAULT, BET_21_PLUS_3, BET_PERFECT_PAIRS } = config
const { EV_SEND_BETS } = config.events
const { BETS_ACCEPTED, BETS_REJECTED } = config.texts
const { PENDING, LOSE, DRAW } = config.mainBetResult

/**
 * SEND ALL BETS
 * @param state
 */
export const  sendBets = (state) => {
  const bet_main = state.currentBet.find(b => b.type === BET_DEFAULT)?.val
  const bet_21 = state.currentBet.find(b => b.type === BET_21_PLUS_3)?.val
  const bet_pp = state.currentBet.find(b => b.type === BET_PERFECT_PAIRS)?.val
  const payload = {
    mainBet: bet_main || 0,
    perfectCoupleBet: bet_pp || 0,
    twentyThreePlusOneBet: bet_21 || 0,
  }

  state.socket.emit(EV_SEND_BETS, payload, (res) => {
    if (state.currentBet.length) {
      state.cycleText = res.status === 200 ? BETS_ACCEPTED : BETS_REJECTED
      state.sentBetsStatus = res.status
    }
  })
}

export const getMainBetAmount = (state, data) => {
  const { mainBet } = data.betTypes
  if (state.hand2) {
    const { hands } = data.player
    const winHand1 = ![PENDING, LOSE, DRAW].includes(hands[0].mainBet)
    const winHand2 = ![PENDING, LOSE, DRAW].includes(hands[1].mainBet)
    // player gana con ambas manos
    if (winHand1 && winHand2) return mainBet.result - mainBet.value
    // player gana con una de las manos
    else if (winHand1 || winHand2) {
      // condicion ? pierde con la otra mano : empata con la otra mano
      const singleBet = mainBet.value / 2
      return mainBet.value === mainBet.result ? mainBet.result / 2 : (mainBet.result - singleBet * 2)
    }
    // player pierde las dos manos
    return 0
  }
  return mainBet.result - mainBet.value
}