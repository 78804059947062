export const INIT = "init";
export const SET_CHIP = "setChip";
export const SET_GET_CARD = "setMoreNaipes";
export const SET_STAND = "setNoMoreNaipes";
export const SET_2X = "set2X";
export const SET_DIVIDE = "setDivide";
export const SET_INSURANCE = "setInsurance"
export const PREPARE_NEXT_ROUND = "prepareNextRound"
export const OPEN_ACTION_PANEL = "openActionPanel"
export const SET_BOX = "setBox"
export const CHANGE_PHASE = "changePhase"
export const ADD_PLAYER_CARD = "addPlayerCard"
export const ADD_CROUPIER_CARD = "addCroupierCard"
export const SET_PLAYER = "setPlayer"
export const UPDATE_PLAYER = "updatePlayer"
export const CHECK_WINNER = "checkWinner"