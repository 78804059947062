export default {
  VIDEO_QUALITIES: ['low', 'medium', 'high', 'auto'],
  BET_DEFAULT: 'bet_main',
  BET_21_PLUS_3: 'bet_21',
  BET_PERFECT_PAIRS: 'bet_pp',
  phases: {
    INITIAL_BET: 'initial_bet',
    RECEIVE_INITIAL_BET: 'revice_initial_bet',
    INITIAL_EMIT_CARD: 'initial_emit_card',
    EMIT_CARD: 'emit_card',
    SAFE_BET: 'safe_bet',
    INITIAL_OPTION: 'initial_option',
    OPTION: 'option',
    PROCESSING: 'processing',
    FINISH: 'finish',
    END: 'end',
  },
  events: {
    EV_CONNECT_PLAYER: '/game/connect/player',
    EV_CHANGE_PHASE: '/game/change-phase',
    EV_SEND_BETS: '/game/bet',
    EV_PLAYER_CARD: '/game/draw/player',
    EV_CROUPIER_CARD: '/game/draw/croupier',
    EV_LATERAL_BETS_RESULT: '/game/lateral-bet/result',
    EV_END_GAME: '/game/end-phase',
    EV_ERROR: '/game/throw_error',
    EV_REMAINING_PLAYERS: '/game/options/selected',
    EV_GET_CARD: '/game/request',
    EV_STAND: '/game/stand',
    EV_DOUBLE_BET: '/game/double-bet',
    EV_DIVIDE: '/game/split',
    EV_SAFE_BET: '/game/safe-bet',
  },
  texts: {
    WAIT_NEXT_GAME: 'wait_next_game',
    WAIT_OTHER_PLAYERS: 'wait_other_players',
    LAST_BETS: 'last_bets',
    CROUPIER_WINS: 'croupier_wins',
    BETS_ACCEPTED: 'bets_accepted',
    BETS_REJECTED: 'bets_rejected',
    NOT_MONEY: 'not_money',
  },
  mainBetResult: {
    PENDING: 'pending',
    LOSE: 'lose',
    DRAW: 'draw',
    WIN_BY_POINTS: 'winByPoints',
    BLACKJACK: 'blackjack',
    BLACKJACK_SPADES: 'blackjackWithSpadeCards',
    TWENTY_ONE_3_7: 'twentyOneWithThreeSeven',
    TWENTY_ONE_7_CARDS: 'twentyOneWithSevenCards',
    TWENTY_ONE_ANY: 'twentyOneWithAnotherCombination',
  },
  icons: {
    WIN_ICON: 'assets/actions/win.svg',
    LOSE_ICON: 'assets/actions/lose.svg',
    GET_CARD_ICON: 'assets/actions/get.webp',
    STAND_ICON: 'assets/actions/stand.webp',
    DOUBLE_BET_ICON: 'assets/actions/2x.webp',
    DIVIDE_ICON: 'assets/actions/divide.webp',
  },
  languages: [
    { label: 'Español', lang: 'es', ico: 'es' },
    { label: 'English', lang: 'en', ico: 'en' },
    { label: 'Português', lang: 'pt', ico: 'pt' },
  ],
};