import axios from "axios"

const endpoint = process.env.VUE_APP_SOCKET

export const instance = axios.create({
  baseURL: endpoint,
  withCredentials: false,
  // headers: {
  //   'Accept': 'application/json',
  //   'Content-Type': 'application/json',
  //   "Access-Control-Allow-Origin": '*',
  // },
})
