import { createRouter, createWebHistory } from "vue-router";
import store from "@/store/index";
import {SET_LOBBY_URL} from "@/store/type/mutations";

const routes = [
  {
    path: "/game",
    name: "home",
    component: () => import('@/views/HomeView.vue'),
    meta: {
      title: 'Blackjack - Game'
    },
  },
  {
    path: "/togame/:token",
    name: "to-game",
    component: () => import('@/views/ToGame.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import('@/views/NotFoundView.vue'),
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const { token } = to.params
  const { casinoId: gameId, casinoToken, operatorId, currency,  language, lobbyUrl } = to.query

  !store.state.game.lobby_url && store.commit(SET_LOBBY_URL, lobbyUrl)
  const validate = () => {
    return !!(token && gameId && casinoToken && operatorId && currency && language && lobbyUrl)
  }
  if (to.name === 'to-game' && !validate()) {
    return next({name: 'not-found'})
  }
  if (to.name === 'home' && !store.state.socket) return next({name: 'not-found'})

  return next()
})

export default router;
