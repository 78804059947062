const pt = {
    // TOP MENU
    'select_limits'  : 'Selecionar limites de aposta',
    'settings'       : 'Configurações',
    'info'           : 'Informação',
    'limits'         : 'Limites',
    'volume'         : 'Volume',
    'language'       : 'Linguagem',
    'fullscreen'     : 'Tela Cheia',
    'help'           : 'ajuda',
    'history'        : {
        'debit'      : 'débito',
        'credit'     : 'crédito',
        'main'       : 'História',
        'title'      : 'histórico de apostas',
        'records'    : 'registros',
        'date'       : 'data',
        'trans'      : 'transação',
        'amount'     : 'quantia',
        'state'      : {
            'main'   : 'resultado',
            'win'    : 'ganho',
            'lose'   : 'perdido',
            'draw'   : 'empate',
        },
        'end'        : 'Estes são todos os resultados!',
        'no-records' : 'Nenhuma aposta foi feita!',
        'no-results' : 'Nenhum resultado encontrado!',
        'axios-err'  : 'Um erro ocorreu! Verifique a sua conexão com a internet.',
        'start_date' : 'Data Início',
        'end_date'   : 'Data Final',
        'dist'       : 'Distribuição de Cartões',
        'points'     : 'pontos',
        'bet_details': 'detalhes da aposta',
        'bet_type'   : 'Tipo Aposta',
        'bet_amount' : 'Quantidade Apostada',
        'won_amount' : 'Quantidade Ganha',
        'tot_amount' : 'Quantidade Total',
        'mainBet'    : 'Principal',
        'twentyThreePlusOne' : '21+3',
        'perfectCouples'     : 'Pares Perfeitos',
        'sureBet'            : 'Seguro',
    },
    // LOBBY CONFIRM
    'exit_lobby'     : 'Tem certeza de que deseja voltar ao lobby?',
    'cancel'         : 'Cancelar',
    'confirm'        : 'Confirme',
    // INACTIVITY
    'inactivity'     : {
        'main'       : 'Inatividade',
        'title'      : 'Inatividade detectada!',
        'msg'        : 'Sentimos muito! Você não pode mais ficar ocioso no jogo.',
        'ok'         : 'Aceitar',
    },
    // SETTINGS BOX
    'general'        : 'Geral',
    'video'          : {
        'title'      : 'Streaming de vídeo',
        'subtitle'   : 'Ative para ver o vídeo ao vivo da mesa',
    },
    'reload'         : {
        'title'      : 'Recarregar vídeo',
        'subtitle'   : 'Recarregue o vídeo',
        'btn'        : 'Recarregar',
    },
    'video_quality'  : {
        'title'      : 'Qualidade de vídeo',
        'subtitle'   : 'Selecione a qualidade em que o vídeo é transmitido',
        'btn'        : {
            'low'    : 'Baixa',
            'medium' : 'Média',
            'high'   : 'Alta',
            'auto'   : 'Auto',
        },
    },
    'volume_in_box'  : {
        'title'      : 'Volume do jogo',
        'subtitle'   : 'Ajustar os efeitos sonoros do jogo',
    },
    'auto_stand'        : {
        'main'          : 'Auto-Planta',
        'descript1'     : 'Se o Auto-Planta estiver ativado, ele representará você automaticamente quando a pontuação de suas cartas for maior ou igual ao valor selecionado.',
        'alert'         : 'Mudanças serão aplicadas na próxima rodada',
        'descript2'     : {
            'title'     : 'Auto-Planta não vai ficar para você quando:',
            'subtitle1' : '1- A opção de divisão está disponível',
            'subtitle2' : '2- Você tem uma Mão Suave (um Ás + o resto das cartas somam menos de 10 pontos)',
        },
        'disabled'      : 'Desabilitado',
    },
    // INFO BOX
    'table'         : 'Mesa',
    'round'         : 'Rodada',
    'croupier'      : 'Crupiê',
    'player'        : 'Jogador',
    'game_version'  : 'Versão do jogo',
    // LIMITS BOX
    'limits_box'    : {
        'title'     : 'Limites de aposta',
        'main'      : 'Principal',
        'secondary' : 'Secundárias',
        'unset'     : 'Não configurado',
    },
    // CHAT
    'chat_form_msg' : 'Escreva uma mensagem aqui',
    // MAIN SCREEN
    'months' : {
        '1'  : 'Janeiro',
        '2'  : 'Fevereiro',
        '3'  : 'Março',
        '4'  : 'Abril',
        '5'  : 'Maio',
        '6'  : 'Junho',
        '7'  : 'Julho',
        '8'  : 'Agosto',
        '9'  : 'Setembro',
        '10' : 'Outubro',
        '11' : 'Novembro',
        '12' : 'Dezembro',
    },
    'money'              : 'Fundos',
    'total_bet'          : 'Aposta Total',
    'auto_stand_on'      : 'auto-planta ativado',
    'undo'               : 'Desfazer',
    'repeat_bet'         : 'Repetir última aposta',
    'double_bet'         : 'Aposta dupla',
    'chip_to_bet'        : 'Ficha para apostar',
    'all_side_bets'      : 'todas as ap. paralelas',
    'winning_value'      : 'Você ganha',
    'main_bet_first'     : 'Você deve fazer uma aposta principal',
    'zoom_in'            : 'Aumentar',
    'zoom_out'           : 'Reduzir',
    // BET PLACE
    'wait_next_game'     : 'Aguarde o próximo jogo',
    'wait_other_players' : 'Aguarde outros jogadores',
    'initial_bet'        : 'Faça suas apostas',
    'last_bets'          : 'últimas apostas',
    'revice_initial_bet' : 'Apostas fechadas',
    'bets_accepted'      : 'Apostas aceitas',
    'bets_rejected'      : 'Apostas recusadas',
    'processing'         : 'Negociando ',
    'initial_emit_card'  : 'Negociando ',
    'emit_card'          : 'Negociando ',
    'safe_bet'           : 'Seguro de aposta?',
    'initial_option'     : 'Tome uma decisão',
    'option'             : 'Tome uma decisão',
    'finish'             : 'Negociando ',
    'croupier_wins'      : 'Crupiê ganha',
    'not_money'          : 'Fundos insuficientes',
    'not_money_desc'     : 'seus fundos não são suficientes para apostar',
    'game_pause'         : 'jogo pausado',
    'game_cancel'        : 'jogo cancelado',
    'yes'                : 'sim',
    'no'                 : 'não',
    'hand1'              : 'Tua mão',
    'hand2'              : 'Tua mão 2',
    // ACTION PANEL
    'double'             : 'dupla',
    'hit'                : 'compre',
    'stand'              : 'ficar',
    'divide'             : 'dividir',
    // MODAL
    'error' : {
        'main'            : 'Opa!',
        'title'           : 'Um erro ocorreu!',
        'connection'      : 'Não pôde conectar-se ao servidor. Volte ao lobby e tente novamente.',
        'action'          : 'A ação não pôde ser concluída. A comunicação com o servidor falhou.',
        'not_found'       : 'Página não encontrada. Volte ao lobby e tente novamente.',
        'no_redirect'     : 'A solicitação não pôde ser enviada. Por favor, tente novamente.',
    },
}

export default pt