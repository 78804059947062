export default class Chip {
  val;
  text;
  active;
  color;
  constructor(val, color) {
    this.val = val;
    this.color = color
    this.text = val;
    this.active = false;

  }
}